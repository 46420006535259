import React from "react";
import {graphql,Link} from 'gatsby';
import Image from "gatsby-plugin-sanity-image"

import BlockContent from '@sanity/block-content-to-react'
import Layout from "../components/layout"
import SEO from "../components/seo"

import SanityImage from '../components/sanityImage'
import UtilityStoryitem from "../components/utilityStoryitem"

import {OutboundLink} from "gatsby-plugin-google-gtag"

export default({data}) => {

  if(data.offer.isDeleted){
    //document.location.href = '/'
  }

  return(
    <Layout>
      <SEO
        title={data.offer.title+' | Gratis.no'}
        description={"Lyst på "+data.offer.title+"? Trykk deg inn her og bestill gratis."}
        image={data.offer.image.asset.url}
      />
      <section className="section novi-bg novi-bg-img section-lg">
        <div className="container">
          <div className="">
            <h1>{data.offer.title}</h1>
            <div  className="mt-40" style={{position:'relative',textAlign:'center'}}>
              {data.offer.value && data.offer.value > 0 &&
              <div className="post-classic-badge">Verdi
                <div className="post-classic-price-value">{data.offer.value}</div>
              </div>
              }
              {/*<Img alt={data.offer.title} fluid={data.offer.image.asset.fluid} style={{maxWidth: 900,maxHeight:500}}></Img>*/}
              <SanityImage alt={data.offer.title} maxWidth={900} maxHeight={500} mode={"containY"} image={data.offer.image}/>
            </div>

            <div style={{textAlign:'right',width:'100%',paddingBottom:'20px',paddingTop:'20px'}}>
                <OutboundLink
                onClick={e => {
                  // To stop the page reloading
                  //e.preventDefault()
                  // Lets track that custom click
                  /*trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Conversion",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "External click",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: data.offer.gkid.current
                  })*/
                  //... Other logic here
                }}
                target="_blank" rel="nofollow noreferrer noopener" className={"button button-lg button-primary-2"} href={'https://out.gratis.no/' + data.offer.gkid.current + '/bestill'}>Bestill nå &raquo;</OutboundLink>
            </div>

            <div style={{fontSize: '130%'}}>
              <BlockContent blocks={data.offer._rawDescription} serializers={serializers} />
            </div>

            {data.offer.provider != null && data.offer.provider.length > 0 &&
              <div style={{fontSize: '120%',width: '100%',textAlign:'right',paddingTop:'15px'}}>
                Tilbyder: <OutboundLink
                              onClick={e => {
                                // To stop the page reloading
                                //e.preventDefault()
                                // Lets track that custom click
                                /*trackCustomEvent({
                                  // string - required - The object that was interacted with (e.g.video)
                                  category: "Conversion",
                                  // string - required - Type of interaction (e.g. 'play')
                                  action: "Click",
                                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                  label: "External click",
                                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                  value: data.offer.gkid.current
                                })*/
                                //... Other logic here
                              }}
                            target="_blank" rel="nofollow noreferrer noopener" href={'https://out.gratis.no/' + data.offer.gkid.current + '/bestill'}>{data.offer.provider}</OutboundLink>
              </div>
            }

          </div>
        </div>
      </section>

      {/* Populære gratisting */}
      <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow mt-40">
        <div className="container">
          <h2 style={{marginBottom:'80px'}} className="text-center text-sm-left mt-50">Populære <span className="h2-style">Gratisting</span></h2>
          <div className="row row-50">

            {data.popOffer.edges.map(({ node: offer }) => (
              <div key={offer.gkid.current} className="col-lg-3 col-sm-6">
                <UtilityStoryitem slug={offer.slug.current} headline={offer.title} image={offer.image} value={offer.value}/>
              </div>
            ))}

          </div>
          <div className="mt-xl-40 mt-50 text-center text-sm-right"><a
            className="button button-icon button-icon-right button-black" href="/populare-gratisting"><span
            className="icon novi-icon fa fa-chevron-right"/>Vis alle populære</a></div>
        </div>
      </section>
    </Layout>
  )
}

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

export const query = graphql`
    query ($slug: String){ 
      offer: sanityOffer(slug: {current: {eq: $slug}}){
        slug{current}
        gkid{current}
        isDeleted
        title
        value
        provider
        image {
            ...ImageWithPreview
            }
        _rawDescription
      }

    popOffer: allSanityOffer(limit: 4, sort: {fields: popularity_rank, order: DESC}, filter: {isDeleted: {ne: true}}) {
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image {
                ...Image
            }
          }
        }
      }
          }
  `

